export const addressParser = {
  api: 'https://cfs-address-parser-api-staging.hmhsistemas.com.mx/',
  key: 'OJ3galR5G-P9kmaJ0LggTThnTVvrtpcDr9PY-5SB',
  unique_address_service: {
    name: 'address_parser-TESTING-placekey_plugin_lambda',
    apply: true
  },
  geocode_service: {
    name: 'address_parser-TESTING-google_plugin_lambda',
    apply: true
  },
  centers: {
    apply: true
  },
  typos: {
    apply: true
  }
};
export const apiEndPoint = 'https://cfsnemtapi-qa.hmhsistemas.com.mx/';
export const base_url = `${location.origin}/#/`;
export const layersEndPoint = 'https://cfsnemt-qa.hmhsistemas.com.mx/';
export const map_apiKey = 'AIzaSyDVWUhxq9tidIQCxGQnS_m97Kt5ANmgdZE';
export const fligthmap_apiKey = '4a306390-2c4a-11ec-9a5a-8d77438946f8';
export default {
  addressParser,
  apiEndPoint,
  base_url,
  layersEndPoint,
  map_apiKey,
  fligthmap_apiKey
};
