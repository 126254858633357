export const vapidKey =
  'BA_J-pWfDWcWfqv6sIXP2PG_Mr_nreGqIBiUAGo0JJ-urbqUjMJII_4ppR10upaH3bEN29x8sbZjoWcbC3oDnRw';
export const config = {
  apiKey: 'AIzaSyCctnvGfUArmCRp-88fVm9_q_IrZfIFJmg',
  authDomain: 'qa-nemt-rx-deliveries.firebaseapp.com',
  projectId: 'qa-nemt-rx-deliveries',
  storageBucket: 'qa-nemt-rx-deliveries.appspot.com',
  messagingSenderId: '328411209087',
  appId: '1:328411209087:web:5eb47da9972a1ef4460e4a',
  measurementId: 'G-045F5RGR6D'
};